import React from "react"
import PropTypes from "prop-types"

const Facebook = ({ color }) => {
  
return (
<svg width="24px" height="24px" fill={color} viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><title>Speaker Deck icon</title><path d="M10.025 13.875H4.687a4.687 4.687 0 1 1 0-9.375h6.227a1.875 1.875 0 0 1 0 3.75H4.592a.937.937 0 1 0 0 1.875h5.337a4.687 4.687 0 1 1 0 9.375H1.875a1.875 1.875 0 1 1 0-3.75h8.15a.937.937 0 1 0 0-1.875zM13.97 19.5a5.635 5.635 0 0 0 2.396-3.75h3.026a.93.93 0 0 0 .921-.938V9.189a.93.93 0 0 0-.921-.938h-5.497a2.83 2.83 0 0 0 .704-1.875 2.83 2.83 0 0 0-.704-1.875h6.418C22.35 4.5 24 6.179 24 8.25v7.5c0 2.071-1.65 3.75-3.687 3.75Z"/></svg>
)
}

Facebook.propTypes = {
  color: PropTypes.string,
}

Facebook.defaultProps = {
  color: "#000000",
}

export default Facebook
