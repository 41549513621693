import React, { useRef, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"

import { useOnScreen } from "../../hooks/"
import Context from "../../context/"
import ContentWrapper from "../../styles/contentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  //background: ${({ theme }) => theme.colors.background};
  margin-top: 0px;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: row;
      justify-content: space-between;
    }
    .section-title {
      margin-bottom: 2rem;
    }
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .text-content {
      width: 100%;
      max-width: 31.25rem;
    }
    .image-content {
      width: 100%;
      max-width: 18rem;
      margin-top: 4rem;
      margin-left: 0;

      display:flex;
      justify-content:center;
      align-items:center;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left: 2rem;
      }
    }
    .about-author {
      border-radius: ${({ theme }) => theme.borderRadius};
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.16);
      filter: grayscale(20%) contrast(1) brightness(90%);
      transition: all 0.3s ease-out;
      &:hover {
        filter: grayscale(50%) contrast(1) brightness(90%);
        transform: translate3d(0px, -0.125rem, 0px);
        box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.32);
      }
    }
  }
`

const About = ({ content, lang }) => {
  const { frontmatter, body } = content[0].node
  const { isIntroDone = true } = useContext(Context).state
  const tControls = useAnimation()
  const iControls = useAnimation()

  // Required for animating the text content
  const tRef = useRef()
  const tOnScreen = useOnScreen(tRef)

  // Required for animating the image
  const iRef = useRef()
  const iOnScreen = useOnScreen(iRef)

  // Only trigger animations if the intro is done or disabled
  useEffect(() => {
    if (isIntroDone) {
      if (tOnScreen) tControls.start({ opacity: 1, y: 0 })
      if (iOnScreen) iControls.start({ opacity: 1, x: 0 })
    }
  }, [isIntroDone, tControls, iControls, tOnScreen, iOnScreen])


  return (
    <StyledSection>
      <StyledContentWrapper>
        <div style={{ display: 'flex', flexDirection: 'column' }}>


          <h1 style={{ color: '#1D2B53' }}>即刻救援 - 最專業快速的緊急助手</h1>
          <img id="about" src='/images/banner.jpg' style={{ width: '100%', borderRadius: 10 }} />

          <h2 >365 x 24 不中斷緊急服務</h2>
          <p style={{ fontSize: '1.2rem' }}>我們是您的緊急修繕夥伴，致力於提供快速、可靠的解決方案。不管是居家、辦公室或商業空間，我們隨時待命，隨時為您解決各種緊急修繕需求</p>



          <a href='#contact'

            style={{
              padding: 10,
              width: 200,
              borderRadius: 10,
              backgroundColor: '#BF3131',
              color: '#EAD196',
              textAlign: 'center'
            }}

          >立刻聯繫 !</a>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', marginTop: 100 }}>
            {
              [
                '牆面修復', '漏水處理', '屋頂維修', '水龍頭更換', '補地板裂縫', '門窗密封', '冷氣漏水', '燈泡更換', '門窗玻璃補修', '水槽阻塞處理', '瓦斯爐檢修', '馬桶漏水處理', '門鎖更換'
              ].map((text, idx) => {
                const color = [
                  '#ECCBD9', '#E1EFF6', '#97D2FB',
                  '#83BCFF', '#80FFE8'
                ][idx % 5];

                return (
                  <div aria-label={text} key={text} style={{ width: '23%', minWidth: 130, maxWidth: 400, backgroundColor: color, display: 'flex', justifyContent: 'center', alignItems: 'flex', marginBottom: 10, marginRight: '2%' }}>
                    {text}
                  </div>
                )
              })
            }

          </div>


          <a href='#contact'

            style={{
              padding: 10,
              width: 200,
              borderRadius: 10,
              backgroundColor: '#BF3131',
              color: '#EAD196',
              textAlign: 'center',
              marginTop: 100
            }}

          >立刻聯繫 !</a>
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )





}

About.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default About
