import React, { useState, useEffect, useRef, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import VisibilitySensor from "react-visibility-sensor"
import { motion } from "framer-motion"

import { useOnScreen } from "../../hooks"
import Context from "../../context"
import ContentWrapper from "../../styles/contentWrapper"
import Underlining from "../../styles/underlining"
import Button from "../../styles/button"
import Icon from "../../components/icons"
import { lightTheme, darkTheme } from "../../styles/theme"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 6rem;
  .cta-btn {
    display: block;
    text-align: center;
    margin: 2rem auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin: 0 auto;
    }
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .section-title {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .projects {
      display: flex;
      flex-direction: row;
      margin-top: -2.5rem;
      padding: 2.5rem 2.5rem;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-direction: column;
        margin-top: 0;
        padding: 0;
        overflow: visible;
      }
      /* Show scrollbar if desktop and wrapper width > viewport width */
      @media (hover: hover) {
        scrollbar-color: ${({ theme }) => theme.colors.scrollBar} transparent; // Firefox only
        &::-webkit-scrollbar {
          display: block;
          -webkit-appearance: none;
        }

        &::-webkit-scrollbar:horizontal {
          height: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: 0.2rem solid ${({ theme }) => theme.colors.background};
          background-color: ${({ theme }) => theme.colors.scrollBar};
        }

        &::-webkit-scrollbar-track {
          background-color: ${({ theme }) => theme.colors.background};
          border-radius: 8px;
        }
      }
    }
    .counter {
      position: absolute;
      top: 2.2rem;
      right: 2.5rem;
      font-size: 1.125rem;
      font-weight: 500;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        display: none;
      }
    }
  }
`

const StyledProject = styled(motion.div)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0;
  margin-bottom: 2rem;
  flex-shrink: 0;
  padding-right: 2.5rem;
  max-width: 20rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    max-width: 25rem;
    margin-top: 2rem;
    padding-right: 5rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: space-between;
    flex-shrink: 1;
    max-width: 62.5rem;
    margin-bottom: 10rem;
    padding-right: 0;
    /* Positioning of image and details should vary */
    flex-direction: ${({ position }) =>
      position % 2 !== 0 ? "row" : "row-reverse"};
  }
  .details {
    width: 100%;
    max-width: 25rem;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 0;
    }
    .category {
      font-size: 0.875rem;
      line-height: 1rem;
      text-transform: uppercase;
      letter-spacing: +1px;
    }
    .title {
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
      font-size: 1.375rem;
      line-height: 1.625rem;
      font-weight: 700;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.5rem;
      line-height: 1.2rem;
      span {
        margin-right: 1rem;
        margin-bottom: 1rem;
      }
    }
    .links {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-top: 1rem;
      a {
        display: inline-block;
        margin-right: 2rem;
      }
      svg {
        width: 1.3rem;
        height: 1.3rem;
        transition: all 0.3s ease-out;
      }
      svg:hover {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
  .screenshot {
    width: 100%;
    max-width: 25rem;
    height: 15rem;
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.16);
    transition: all 0.3s ease-out;
    &:hover {
      transform: translate3d(0px, -0.125rem, 0px);
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.32);
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      height: 18.75rem;
    }
  }
`

const Projects = ({ content,lang }) => {
  const { darkMode } = useContext(Context).state
  const sectionDetails = content[0].node
  const projects = content.slice(1, content.length)

  // visibleProject is needed to show which project is currently
  // being viewed in the horizontal slider on mobile and tablet
  const [visibleProject, setVisibleProject] = useState(1)

  // projects don't track the visibility by using the onScreen hook
  // instead they use react-visibility-sensor, therefore their visibility
  // is also stored differently
  const [onScreen, setOnScreen] = useState({})
  const handleOnScreen = el => {
    if (!onScreen[el]) {
      const updatedOnScreen = { ...onScreen }
      updatedOnScreen[el] = true
      setOnScreen(updatedOnScreen)
    }
  }
  const pVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  useEffect(() => {
    // mobile and tablet only: set first project as visible in the
    // horizontal slider
    setVisibleProject(1)
    // required for animations: set visibility for all projects to
    // "false" initially
    let initial = {}
    projects.forEach(project => {
      initial[project.node.frontmatter.position] = false
    })
    setOnScreen(initial)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Required for animating the title
  const tRef = useRef()
  const tOnScreen = useOnScreen(tRef)
  const tVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  // Required for animating the button
  const bRef = useRef()
  const bOnScreen = useOnScreen(bRef)
  const bVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  return (
    <StyledSection id="projects">
      <StyledContentWrapper>
        <motion.div
          ref={tRef}
        >
          <h2 className="section-title">{lang === 'tw' ? sectionDetails.frontmatter.title : 'History'}</h2>
          <div className="projects">
        <MyPast lang={lang} />
        </div>
        </motion.div>
        
       </StyledContentWrapper>
    </StyledSection>
  )
}

const MyPast = ({lang}) => {
  const Sh3 = _ => <h3 style={{margin:0, color:'grey', fontSize: '1.24rem', marginTop: _.top ? 0 : 20}}>{_.children}</h3>
  const Scontent = ({children, links=[]}) => {
    const Sa = ({link}) => {
      
      return (
        <a href={link[1]} target='_blank' rel="noreferrer" style={{color:'grey'}} alt={children+':'+link[0]} aria-label={children+':'+link[0]}>{link[0]}&nbsp;</a>
      )
    }
    const h4Comp = <h4 style={{ display:'inline-block', marginTop:0, marginBottom:0}}>{children}</h4>
    return (
      <p style={{margin: 5, '.a:hover':'red'}}>
      {h4Comp}
      {
        links.length > 0 ?  '  [ ' : ''
      }
      {
        links.map((link, idx) => <Sa key={idx} link={link} />)
      }
      {
        links.length > 0 ? ']' : ''
      }
      </p> 
    )
  }

if (lang === 'tw') {
  return (
    <div style={{display:'flex', flexDirection:'column', width:'100%', }}>
      <Sh3 top>學歷</Sh3>
        <Scontent>台大資訊系</Scontent>
        <Scontent>台大資訊所 - 無線網路與嵌入式系統研究室 NEWS Lab</Scontent>
      <Sh3>經歷</Sh3>
        <Scontent>Garmin Platform Team Engineer</Scontent>
        <Scontent>忻旅科技共同創辦人暨技術策略長</Scontent>
        <Scontent>二十五電訊前技術長</Scontent>
        <Scontent>加密鏈科技前執行長</Scontent>
        <Scontent>奕果雲端資訊共同創辦人暨前技術長</Scontent>
        <Scontent>TAICCA文化創業加速器第四期：好好選文創平台
        </Scontent>
      <Sh3>Embedded system & IoT</Sh3>
      <Scontent links={[['github 備份', 'https://github.com/sailplaneTW/opencsl-tutorial-backup']]}>OpenCSL 嵌入式系統開放課程</Scontent>
      <Scontent>AAE (ARM Accredited Engineer) 認證</Scontent>


      <Sh3>課程</Sh3>
      <Scontent links={[['hiskio', 'https://revteltech.pse.is/hiskio-rn-from-sam']]}>最新 React Native 技術｜打造雙平台電商 App</Scontent>
      <Scontent links={[['hiskio', 'https://revteltech.pse.is/hiskio-react-hook-from-sam']]}>無痛玩轉 React Hook｜6 小時打造流量時代超夯的一頁式電商</Scontent>
      <Scontent links={[['hiskio', 'https://revteltech.pse.is/hiskio-fp-from-sam']]}>Web 工程師不可不知的 functional programming 應用｜減少 bug、增進效能有一套</Scontent>
      <Scontent links={[['hiskio', 'https://l.revtel.app/s/hiskio-1465']]}>JavaScript 設計模式全解析｜3 小時內掌握 10 種設計模式</Scontent>

      <Sh3>軌跡</Sh3>
    <Scontent>MOPCON 2022 Panel - 談區塊鏈的意義、現況及未來</Scontent>
      <Scontent links={[['投影片', 'https://l.revtel.app/s/mopcon-2022-keynote']]}>MOPCON 2022 Keynote - 從 IoT 到 Web2，從 Web2 到 Web3 : 那些開發路上的奇妙風景</Scontent> 
      <Scontent links={[['錄影,', 'https://youtu.be/k-PyL4ikDH8'],['投影片', 'https://revteltech.pse.is/jsdc2021-from-sam']]}>JSDC 2021 - 從 React Native 跨屏開始的多領域開發歷程</Scontent> 
      <Scontent links={[['NFC 方案', 'https://revteltech.pse.is/nfc-hootoo-from-sam']]}>亞洲矽谷虎頭山創新園區開幕典禮 Demo</Scontent>
      <Scontent links={[['Inigma 採訪,', 'https://revteltech.pse.is/inigma-from-sam'], ['數位時代採訪', 'https://revteltech.pse.is/inigma-from-sam-2']]}>NFC 冷錢包發展介紹</Scontent>
      <Scontent links={[['2019 Hit AI & Blockchain', 'https://revteltech.pse.is/2019hitaiblockchain-from-sam']]}>NFC 冷錢包獲獎</Scontent>
      <Sh3>短文</Sh3>
    <Scontent links={[['行銷人', 'https://tw.stock.yahoo.com/news/%E5%A4%96%E5%8C%85%E5%90%88%E7%B4%84%E8%A6%81%E6%80%8E%E9%BA%BC%E5%AF%AB-%E8%BB%9F%E9%AB%94%E9%96%8B%E7%99%BC%E5%90%88%E7%B4%84%E8%A6%81%E6%B3%A8%E6%84%8F%E7%9A%84%E5%9C%B0%E6%96%B9-060835377.html']]}>外包合約要怎麼寫？軟體開發合約要注意的地方</Scontent>
    <Scontent links={[['關鍵評論網', 'https://www.thenewslens.com/article/184454']]}>蘋果「Tap to Pay」讓手機變刷卡機，當NFC走入生活能玩出哪些花樣？</Scontent>
    </div>
  )
}
if (lang === 'en') {
  return (
    <div style={{display:'flex', flexDirection:'column', width:'100%', }}>
      <Sh3 top>Education</Sh3>
        <Scontent>Bachelor of Science in Computer Science and Information Engineering(CSIE), National Taiwan University</Scontent>
        <Scontent>Master of Embedded System and Wireless Networking Lab, National Taiwan University</Scontent>
      <Sh3>Experience</Sh3>
        <Scontent>Garmin Platform Team Engineer</Scontent>
        <Scontent>Co-founder and Chief Technology Strategist at RevtelTech</Scontent>
        <Scontent>Former Chief Technology Officer at TEL25</Scontent>
        <Scontent>Former CEO of Inigma</Scontent>
        <Scontent>Co-founder and Chief Technology Officer at EcultureTech</Scontent>
      <Sh3>Embedded system & IoT</Sh3>
      <Scontent links={[['github backup', 'https://github.com/sailplaneTW/opencsl-tutorial-backup']]}>OpenCSL : Open Course on Embedded Systems</Scontent>
      <Scontent>AAE (ARM Accredited Engineer) Cetificate</Scontent>
  
      <Sh3>Events</Sh3>
    <Scontent>MOPCON 2022 Panel - Discussing the Meaning, Current Status, and Future of Blockchain</Scontent>
      <Scontent links={[['Slide', 'https://l.revtel.app/s/mopcon-2022-keynote']]}>MOPCON 2022 Keynote - From IoT to Web2, from Web2 to Web3: Wonderful Scenery Along the Development Road</Scontent> 
      <Scontent links={[['Video,', 'https://youtu.be/k-PyL4ikDH8'],['Slide', 'https://revteltech.pse.is/jsdc2021-from-sam']]}>JSDC 2021 - From Cross-Platform React Native to Multi-Domain Development Journey</Scontent> 
      <Scontent links={[['NFC Solution', 'https://revteltech.pse.is/nfc-hootoo-from-sam']]}>Opening Ceremony of Tiger Head Mountain Innovation Park in Asia Silicon Valley Demo</Scontent>
      <Scontent links={[['Inigma Media Press,', 'https://revteltech.pse.is/inigma-from-sam'], ['DigiTimes Media Press', 'https://revteltech.pse.is/inigma-from-sam-2']]}>Introduction to the Development of NFC Cold Wallet</Scontent>
      <Scontent links={[['2019 Hit AI & Blockchain', 'https://revteltech.pse.is/2019hitaiblockchain-from-sam']]}>NCF Cold Wallet Award</Scontent>

      <Sh3>Posts</Sh3>
    <Scontent links={[['行銷人', 'https://tw.stock.yahoo.com/news/%E5%A4%96%E5%8C%85%E5%90%88%E7%B4%84%E8%A6%81%E6%80%8E%E9%BA%BC%E5%AF%AB-%E8%BB%9F%E9%AB%94%E9%96%8B%E7%99%BC%E5%90%88%E7%B4%84%E8%A6%81%E6%B3%A8%E6%84%8F%E7%9A%84%E5%9C%B0%E6%96%B9-060835377.html']]}>How to write an outsourcing contract? Things to consider in a software development contract (Chinese)</Scontent>
    <Scontent links={[['關鍵評論網', 'https://www.thenewslens.com/article/184454']]}>Apple&apos;s &apos;Tap to Pay&apos; turns your phone into a card reader. What creative possibilities can NFC bring to our daily lives? (Chinese)</Scontent>
    </div>
  )
}
return <div/>
}

Projects.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Projects
