import React from "react"

const Jump = () => {
    React.useEffect(() => {
        const queryString = window.location.search;

        // 使用 URLSearchParams 解析 query string
        const params = new URLSearchParams(queryString);
    
        // 從解析後的對象中獲取特定的參數值
        const paramValue = params.get('go');

        let go = window.location.origin
        if (paramValue.indexOf('survey') > -1) {
            go = 'https://www.surveycake.com/s/Wxl90'
        }

        window.location.href = go;
    }, [])

    return <div/>
}

export default Jump