import React from "react"


import GlobalStateProvider from "../context/provider"
import Layout from "../components/layout"
import SEO from "../components/seo"


import POSTS from "../../content/posts"

const PostsPage = () => {

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState} >
      <Layout lang='tw' style={{ backgroundColor: 'rgb(250, 248, 248)' }}>
        <div style={{ backgroundImage: `url("/images/bg1.jpg")`, }}>

          <SEO
            title={
              '急速修繕：資訊分享'
            }
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '80vh', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          <div style={{ display: 'flex', flexDirection: 'column', minHeight: '80vh', minWidth: 300, maxWidth: 800, width: '90%', justifyContent: 'flex-start', alignItems: 'flex-start', paddingBottom: 50 }}>
            <img src='/images/posts-banner.png' style={{ width: '100%', marginBottom: 40 }} />
            {
              POSTS.map((p, idx) => {
                let color = 'black'
                try {
                  const colorArr = [
                    '#35374B', '#344955', '#50727B', '#78A083', '#337357'
                  ]
                  color = colorArr[idx % (colorArr.length)]
                } catch (err) {
                  color = 'black'
                }
                return (<h2 key={p.path} style={{ margin: 0, marginBottom: 20, fontSize: 24, fontStyle: 'normal', color }}><a rel="noreferrer" style={{ textDecoration: 'none', }} href={'/post/' + p.path} target='_blank'>{p.title}</a></h2>)
              })
            }
          </div>

        </div>
      </Layout>
    </GlobalStateProvider>
  )
}



export default PostsPage

