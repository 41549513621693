import React from "react"


import GlobalStateProvider from "../context/provider"
import Layout from "../components/layout"
import SEO from "../components/seo"


import POSTS from "../../content/posts"
import Config from '../../config/index'

const PostsPage = ({ pageContext }) => {
  const post = pageContext.postData

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState} >
      <Layout lang='tw' style={{ backgroundColor: 'rgb(250, 248, 248)' }}>
        <div style={{ backgroundImage: `url("/images/bg1.jpg")`, }}>

          <SEO
            title={post.title}
            description={post.title}
            image={post.image}
            url={Config.siteUrl + '/' + post.path}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '80vh', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

          <div style={{ display: 'flex', flexDirection: 'column', minHeight: '80vh', maxWidth: 800, minWidth: 300, width: '90%', justifyContent: 'flex-start', alignItems: 'flex-start', paddingBottom: 50 }}>
            <div style={{ width: '100%', height: 300 }}>
              <img src={post.image} alt={post.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <h1>{post.title}</h1>
            <article>{post.content.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}</article>
          </div>

        </div>
      </Layout>
    </GlobalStateProvider>
  )
}



export default PostsPage

